
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import RelationsTable from "@/components/individual/RelationsTable.vue";
import BeneficiaryDetails from "@/components/reusable/BeneficiaryDetails.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import { config } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import BrokerPolicyTableDropdownOptions from "@/components/individual/BrokerPolicyTableDropdownOptions.vue";
import AdminPolicyTableDropdownOptions from "@/components/individual/AdminPolicyTableDropdownOptions.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "individual-client-profile",
  components: {
    RelationsTable,
    BeneficiaryDetails,
    GoBackButton,
    BrokerPolicyTableDropdownOptions,
    AdminPolicyTableDropdownOptions,
  },
  // props: ["publicId"],
  data() {
    return {
      avatarUrl: "",
      route: "",
      brokerRole: variables.BROKER_USER_ROLE,
      adminRole: variables.ADMIN_USER_ROLE,
      superAdminRole: variables.SUPER_ADMIN_USER_ROLE,
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();
    const router = useRouter();
    const tabIndex = ref(0);
    const publicId = router.currentRoute.value.params.publicId;
    const userRole = ref("");
    const claims = ref([
      {
        claimNumber: "",
        policyType: "",
        entity: "",
        lossType: "",
        status: "",
        amount: "",
        settledAmount: "",
        outstandingAmount: "",
        lossDate: "",
        reportedDate: "",
        publicId: "",
        hasClaimNumber: false,
        isSettlementOfferAccepted: false,
        settlementOffer: {},
        isDischargeVoucherApproved: false,
      },
    ]);
    const policies = ref([
      {
        publicId: "",
        businessTypeShortCode: "",
        startDate: "",
        expiryDate: "",
        status: "",
        sumInsured: 0,
        year: "",
        type: "",
        number: "",
        reference: "",
        premium: 0,
      },
    ]);
    const creditNotes = ref([
      {
        serialNumber: "",
        policyNumber: "",
        ibnPolicyNumber: "",
        businessType: "",
        amount: 0,
        status: "",
        transactionDate: "",
        policyPublicId: "",
        publicId: "",
      },
    ]);
    const invoices = ref([
      {
        invoiceNumber: "",
        ibnReference: "",
        endorsementType: "",
        premium: 0,
        narration: "",
        dateCreated: "",
        publicId: "",
      },
    ]);
    const payments = ref([
      {
        policyReference: "",
        serialNumber: "",
        invoiceNumber: "",
        premium: 0,
        paymentChannel: "",
        reference: "",
        dateCreated: "",
        metadata: { data: { status: "" } },
        policyPublicId: "",
        publicId: "",
      },
    ]);
    const profileSummary = ref({
      avatar: "",
    });
    const hospitalSummary = ref({});
    const employerSummary = ref({});
    const relationsSummary = ref({});
    const beneficiariesSummary = ref({});

    const policyDetailsRoute = (role: string): string => {
      let route = "";

      switch (role) {
        case variables.FINANCE_USER_ROLE:
          route = `${variables.FINANCE_CLIENTS_ROUTE}/${publicId}`;
          break;
        case variables.BROKER_USER_ROLE:
          route = `${variables.BROKER_CLIENTS_LIST_ROUTE}/${publicId}`;
          break;
        case variables.ADMIN_USER_ROLE:
          route = `${variables.SUPERADMIN_CLIENT_DETAILS_ROUTE}/${publicId}`;
          break;
        case variables.SUPER_ADMIN_USER_ROLE:
          route = `${variables.SUPERADMIN_CLIENT_DETAILS_ROUTE}/${publicId}`;
          break;
        default:
          route = "";
      }

      return route;
    };

    const getClientInformation = async (role) => {
      const route = policyDetailsRoute(role);

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(route)
          .then(({ data }) => {
            claims.value = data.data.claims;
            policies.value = data.data.policies;
            creditNotes.value = data.data.creditNotes;
            invoices.value = data.data.invoices;
            payments.value = data.data.payments;
            profileSummary.value = data.data.personalInformation;
            hospitalSummary.value = data.data.hospitalInformation;
            employerSummary.value = data.data.employerInformation;
            relationsSummary.value = data.data.relationshipInformation;
            beneficiariesSummary.value = data.data.beneficiariesInformation;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      userRole.value = JwtService.getUserRole() as string;
      getClientInformation(userRole.value);
      setCurrentPageBreadcrumbs("Profile Information", ["User"]);

      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      displayDatatable([
        "basicExample",
        "basicExample1",
        "basicExample2",
        "basicExample3",
        "basicExample4",
      ]);
    });

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    return {
      tabIndex,
      config,
      setActiveTab,
      reinitialization,
      useReusableNavigation,
      userRole,
      claims,
      policies,
      creditNotes,
      invoices,
      payments,
      profileSummary,
      hospitalSummary,
      employerSummary,
      relationsSummary,
      beneficiariesSummary,
    };
  },
  created() {
    //Set page title
    document.title = "Profile Information | " + process.env.VUE_APP_TITLE;

    // Append route to Avatar URL
    this.avatarUrl = variables.AVATAR_ROUTE;
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    removeUnderscore(string) {
      return variables.removeUnderscore(string);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
  },
});
